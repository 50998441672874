<template>
	<v-dialog v-model="dialog" fullscreen hide-overlay persistent transition="scale-transition" @input="closeDialog()">
		<v-card class="fill-height full-width">
			<v-layout column fill-height>
				<v-flex shrink>
					<PreviewSideBar
						v-if="displaySideBar"
						v-model="model"
						:display-thumbnails="showPagesThumbnails"
						:index="currentDocumentIndex"
						:page="selectedDocumentPageNumber"
						:total-documents="numberOfDocuments"
						:total-pages="totalPages"
						:version="documentVersion"
						:is-editable="isEditable"
						:closable="closable"
						@close="hideSideBar"
					/>
					<PreviewToolBar
						v-else
						v-model="model"
						:items="items"
						:display-thumbnails="showPagesThumbnails"
						:index="currentDocumentIndex"
						:page="currentPage"
						:total-documents="numberOfDocuments"
						:total-pages="totalPages"
						:version="documentVersion"
						:is-editable="isEditable"
						:single-file-mode="singleFileMode"
						:closable="closable"
						@close="closeDialog()"
					/>
					<BottomBar
						v-if="!conditionalDisplay.isMobileApp && $vuetify.breakpoint.lgAndDown"
						v-model="model"
						:display-thumbnails="false"
						:page="currentPage"
						:total-documents="numberOfDocuments"
						:total-pages="totalPages"
						:version="documentVersion"
						:is-editable="isEditable"
						:single-file-mode="singleFileMode"
					/>
				</v-flex>
				<v-flex scroll-y>
					<v-layout v-if="!displaySideBar" fill-height align-center justify-center>
						<v-progress-circular v-if="loading" indeterminate color="primary" />
						<v-flex v-else-if="!viewer">
							<span v-t="'documents.preview.no_viewer'" />
						</v-flex>
						<component
							:is="viewer" v-else-if="fileURL" :display-thumbnails="showPagesThumbnails"
							:document="model" :version="documentVersion" :page.sync="currentPage" :src="fileURL"
							:read-only-mode="conditionalDisplay.isMobileApp" :total-pages.sync="totalPages"
							@page-focus="setCurrentPage" @update:is-static="isStaticViewer = $event" />
					</v-layout>
				</v-flex>
			</v-layout>
			<v-snackbar bottom color="warning" right :timeout="0" :value="warnNewVersion">
				<w-layout align-center row>
					<v-icon class="mr-2">sync</v-icon>
					{{ $t('documents.preview.a_new_version_is_available') }}
					<w-btn color="" flat @click="onRefreshDocument(model.id)">{{ $t('actions.refresh') }}</w-btn>
				</w-layout>
			</v-snackbar>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import * as Files from '@/helpers/files'

export default {
	name: 'DocumentPreview',
	components: {
		PreviewSideBar: () => ({
			component: import('@/components/Documents/Preview/PreviewSideBar')
		}),
		PreviewToolBar: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar')
		}),
		BottomBar: () => ({
			component: import('@/components/Documents/Preview/BottomBar.vue')
		}),
		PDFViewer: () => ({
			component: import('@/components/Documents/Preview/Viewers/PDFViewer')
		}),
		ImageViewer: () => ({
			component: import('@/components/Documents/Preview/Viewers/ImageViewer')
		}),
		LibreOfficeViewer: () => ({
			component: import('@/components/Documents/Preview/Viewers/LibreOfficeViewer')
		})
	},
	mixins: [DocumentVersionsModuleGuard],
	props: {
		loading: {
			type: Boolean,
			required: false,
			default: false
		},
		items: {
			type: Array,
			required: false,
			default: () => []
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		isEditable: {
			type: Boolean,
			required: false,
			default: true
		},
		closable: {
			type: Boolean,
			required: false,
			default: true
		},
		singleFileMode: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: Object,
			required: false,
			default: null
		},
		readOnlyMode: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			currentPage: 1,
			oldCurrentPage: 1,
			dialog: true,
			fileURL: null,
			isStaticViewer: true,
			displaySideBar: false,
			firstVersion: null,
			showPagesThumbnails: this.$vuetify.breakpoint.mdAndUp,
			selectedDocumentPageNumber: 1,
			totalPages: 1,
			warnNewVersion: false,
			selectedDocumentNumberOfPages: 0
		}
	},
	computed: {
		...mapState({
			currentDocument: state => state.documents.preview.current,
		}),
		...mapGetters({
			previewHasPreventedKeyboardKey: 'documents/preview/hasPreventKeyboardKey'
		}),
		currentDocumentIndex: function () {
			return this.items.findIndex(item => item.id === this.currentDocument?.id)
		},
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		isSpecificVersionSelected: function () {
			return this.version !== null && this.currentDocument?.latestVersion?.id !== this.version.id
		},
		numberOfDocuments: function () {
			return this.items.length
		},
		viewer: function () {
			let result = null
			if (['pdf', Files.mimetypes.pdf, Files.mimetypes.pdf_a].includes(this.currentDocument?.type?.toLowerCase())) {
				result = 'PDFViewer'
			} else if (this.currentDocument?.type?.startsWith('image/')) {
				result = 'ImageViewer'
			} else {
				result = 'LibreOfficeViewer'
			}
			return result
		},
		documentVersion: function () {
			let result
			if (this.version && this.version.document_id == this.currentDocument?.id) {
				result = this.version
			} else if (this.currentDocument?.latestVersion) {
				result = this.currentDocument.latestVersion
			} else if (this.firstVersion && this.firstVersion.document_id == this.currentDocument?.id) {
				result = this.firstVersion
			}
			return result
		}
	},
	watch: {
		currentDocument: {
			handler: function () {
				this.selectedDocumentPageNumber = 1
				this.totalPages = 1
				this.warnNewVersion = false
				this.selectedDocumentNumberOfPages = 0
				this.loadVersion()
			}
		}
	},
	created: function () {
		if (this.currentDocument) {
			this.loadVersion()
		}
	},
	methods: {
		...mapActions({
			selectNextDocument: 'documents/preview/selectNextDocument',
			selectPreviousDocument: 'documents/preview/selectPreviousDocument',
		}),
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.preview.NEXT_DOCUMENT, action: this.selectNextDocument },
				{ event: this.events.preview.PREVIOUS_DOCUMENT, action: this.selectPreviousDocument },
				{ event: this.events.preview.NEXT_PAGE, action: this.selectNextPage },
				{ event: this.events.preview.PREVIOUS_PAGE, action: this.selectPreviousPage },
				{ event: this.events.preview.HIDE_SIDE_BAR, action: this.hideSideBar },
				{ event: this.events.preview.SHOW_SIDE_BAR, action: this.showSideBar },
				{ event: this.events.preview.TOGGLE_THUMBNAILS, action: this.togglePagesThumbnails },
				{ event: this.events.preview.ROTATE_DOCUMENT, action: this.computeFileURL },
				{ event: this.events.preview.ROTATE_DOCUMENT_PAGE, action: this.computeFileURL },
				{ event: this.events.preview.DELETE_DOCUMENT_PAGE, action: this.computeFileURL },
				{ event: this.events.preview.DOCUMENT_RELOADED, action: this.onDocumentReloaded }
			]
		},
		getKeyboardEventsActionsMapping: function () {
			return [
				{
					actions: [
						{
							key: this.keyboard.arrow.left,
							action: this.keyboardNext,
						},
						{
							key: this.keyboard.arrow.right,
							action: this.keyboardPrev,
						}
					]
				}
			]
		},
		keyboardNext: function () {
			if (this.previewHasPreventedKeyboardKey(this.keyboard.arrow.left)) {
				return;
			}
			this.selectPreviousDocument();
		},
		keyboardPrev: function () {
			if (this.previewHasPreventedKeyboardKey(this.keyboard.arrow.right)) {
				return;
			}
			this.selectNextDocument();
		},
		closeDialog: function () {
			this.$emit('close')
		},
		loadVersion: async function () {
			if (this.documentVersion || !this.currentDocument) {
				this.computeFileURL();
				return;
			}

			try {
				const currentVendorId = this.currentDocument.vendor_id ? this.currentDocument.vendor_id : this.vendorId
				const versions = await this.service.getDocumentVersions(currentVendorId, this.currentDocument);
				this.firstVersion = versions[0];
				this.computeFileURL();
			} catch (error) {
				// eslint-disable-next-line no-console
			}
		},

		computeFileURL: function () {
			this.oldCurrentPage = this.currentPage
			this.setCurrentPage(1)
			if (this.documentVersion) {
				const documentURL = new URL(this.documentVersion.uri)
				const token = this.extractToken(documentURL.href)
				if (token != localStorage.getItem('token')) {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('errors.unauthorized'))
					this.appEventBus.emit(this.appEvents.LOGOUT)
				} else {
					this.fileURL = documentURL.href
					this.informDocumentHasBeenOpened(this.currentDocument)
				}
			}
		},
		onRefreshDocument: function (documentId) {
			if (this.value?.id === documentId) {
				this.computeFileURL()
			}
			this.warnNewVersion = false
		},
		onDocumentReloaded: function () {
			this.$nextTick(() => {
				this.oldCurrentPage = (this.oldCurrentPage > this.totalPages) ? this.totalPages : this.oldCurrentPage
				this.setCurrentPage(this.oldCurrentPage)
			})

		},
		selectNextDocument: function () {
			if (this.items.length > 1 && this.currentDocumentIndex >= 0 && this.currentDocumentIndex < this.items.length - 1) {
				this.model = this.items[this.currentDocumentIndex + 1]
			}
		},
		selectPreviousDocument: function () {
			if (this.items.length > 1 && this.currentDocumentIndex > 0) {
				this.model = this.items[this.currentDocumentIndex - 1]
			}
		},
		selectNextPage: function () {
			if (this.currentPage < this.totalPages) {
				this.currentPage++
			} else {
				throw new Error('PreviewError : The selected page is already the last page of the document')
			}
		},
		selectPreviousPage: function () {
			if (this.currentPage > 1) {
				this.currentPage--
			} else {
				throw new Error('PreviewError : The selected page is already the first page of the document')
			}
		},
		setCurrentPage: function (pageNumber) {
			this.currentPage = pageNumber
		},
		showSideBar: function () {
			this.displaySideBar = true
		},
		hideSideBar: function () {
			this.displaySideBar = false
		},
		togglePagesThumbnails: function () {
			this.showPagesThumbnails = !this.showPagesThumbnails
		},
		informDocumentHasBeenOpened: function (document) {
			this.eventBus.emit(this.events.DOCUMENT_OPENED, document)
		},
		extractToken: function (fileURL) {
			const urlParams = new URLSearchParams(fileURL)
			return urlParams.get('token')
		}
	}
}
</script>
